import React from 'react'
import './component.css';
import PropTypes from 'prop-types';
import logo1 from './logo2.svg';

function Header( props) {
  return (
    <div className=" fird firstd">

    
    <div className="divrow">
        <div className=" col1">
            
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-justify" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
                            </svg>
        </div>
        <div className=" col2">
           
            <img src={logo1} className='logocss' alt='regence'/>
        </div>
        <div className="home" hidden={props.flag} >
          <a href="/" className='ahome'>HOME</a>
        </div>
     
    </div>
    </div>
  )
}

export default Header;

Header.prototype={
  flag: PropTypes.bool
}
