import React from 'react';
import './mainbody.css';

export default function MainBody() {
  return (
    <div className='body'>
      <br /><br /><br /><br /><br /><br />
      <section className='bodymain'>Experience Life with Blue &#174;</section>
      <br /><br /><br />


    <div className="container"> 
        <div className="row rolb" >
            <div className="col colb">
                <img src="https://beonbrand.getbynder.com/m/1fd71f22ab9cc595/original/Imageset-1-2-of-2-.jpg" 
                alt="img1" 
                className='imgP'/>
            </div>
            <div className="col colb">

                <div className="row colb1">
                <img src="https://beonbrand.getbynder.com/m/5bcdc4c36ba8a27c/original/Imageset-2-2-of-2-.jpg" 
                alt="img1" 
                className='imgP1'/>
                </div>
                <div className="row colb1">
                <img src="https://beonbrand.getbynder.com/m/5f156b233b59b353/original/Imageset-3-2-of-2-.jpg" 
                alt="img1"
                className='imgP1' />
                </div>
            
            </div>
            <div className="col colb">
            <img src="https://beonbrand.getbynder.com/m/415f50a22f6e1ccd/original/Imageset-4-2-of-2-.jpg" 
                alt="img1"
                className='imgP' />
            </div>
            <div className="col colb">
            <div className="row colb1">
            <img src="https://beonbrand.getbynder.com/m/c561e8319d6487c/original/Imageset-5-2-of-2-.jpg" 
                alt="img1" 
                className='imgP1'/>
                </div>
                <div className="row colb1">
                <img src="https://beonbrand.getbynder.com/m/b1b156f2e244941/original/Imageset-6-2-of-2-.jpg" 
                alt="img1" 
                className='imgP1'/>  
                </div>
            </div>
            <div className="col colb">
            <img src="https://beonbrand.getbynder.com/m/2a0d96ae4a421abc/original/Imageset-7-2-of-2-.jpg" 
                alt="img1"
                className='imgP' /> 
            </div>
            <div className="col colb">
            <img src="https://beonbrand.getbynder.com/m/67bdfbd315d0044e/original/Imageset-8-2-of-2-.jpg" 
                alt="img1"
                className='imgP' /> 
            </div>
        </div>
        </div> 

        <div className="textContainer">
            <section className="sptext">
            With a plan powered by the most trusted name in health insurance, you’ll have coverage tailored to your health. Your budget. Your life.
            </section>
            <br />
            <section className="sptext">
            All from Regence, a company committed to service, community and this place we call home.
            </section>
        </div>

        <div className="textCont">
            <section className='textsec'>
            Why choose Regence
            </section>
        </div>



    

    </div>
  )
}
