import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import PropTypes from 'prop-types';
import { propTypes } from 'react-bootstrap/esm/Image';
import './component.css';

function NavigationBar(props) {
  return (


    <>
    <div className="mainB">
    <div className='navContainer'>
        <div className='zip'>
                        <span>ZIP Code : 
                            <span>
                            <a href='/' className='zipA'>
                                <u>SET ZIP</u> 
                            </a>
                            </span>
                        </span>
          </div>
          
        <div className='drop' hidden= {props.hidd}>
              <Dropdown className=" ">
              <Dropdown.Toggle variant="" class='btn-outline-light'>
              <svg xmlns="http://www.w3.org/2000/svg" width="26" color='white' height="26" fill="currentColor" className="bi bi-person-fill" viewBox="0 0 16 16">
              <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
              </svg>
                <section className='drophead'>{props.action}</section>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="/login">LOG IN</Dropdown.Item>
                <Dropdown.Item href="/register">REGISTER</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
        </div>

    </div>
    
    
    </div>
    
    
    </>
  );
}

NavigationBar.propTypes = {
  title : PropTypes.string,
  action : PropTypes.string,
  hidd :PropTypes.bool,
}

export default NavigationBar;