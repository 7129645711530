import React from 'react'
import './component.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import MainBody from './MainBody';


export default function SubBody() {
  return (
    <>
    <div>
    <div className="mbody">
    <div className='subbody'>
      <div className='row subrow'>
    <div className="col rcol1">
        <div className='rcolhead'>
            <div className='rcolh'>Looking for a plan?</div>
            <a href='/' className='rcola'>
                <span>Continue my application</span>
            </a>
        </div> 
    </div>


    <div className='col rcol2'>
        <div className='rrcol1'>
            <label> I am looking for a :
                <br />
                <Form.Select aria-label="Default select example">
                <option value="1">Group Policy</option>
                    <option value="2">Individual Policy</option>
                    <option value="3">Individual & Family Plan</option>
    </Form.Select>
            </label>
        </div>
        <div className='rrcol2'>
            <label> ZIP Code :
                <br />
                <input placeholder='Enter Zip code' className='rinp'></input>
            </label>
        </div>
    </div>
    <div className='col rcol3'>

    <Form>
      <Button className='rform' variant='info' type="submit">
        Start Shopping
      </Button>
    </Form>

    </div>
  </div>
    </div>
    </div>
    </div>
    <MainBody />
    </>
  )
}
