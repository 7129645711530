import React,{useState} from 'react';
import NavigationBar from '../Components/NavigationBar';
import {useNavigate} from 'react-router-dom';
import Footer from '../Components/Footer';
import Header from '../Components/Header';

export default function RegisterHome() {

    const [userFullName,setName] = useState("");
    const [userName,setUserName] = useState("");
    const [userEmail,setUserEmail] = useState("");
    const [userPhone,setUserPhone] = useState("");
    const [userDOB,setUserDOB]=useState("");
    const [userPassword,setUserPassword]= useState("");

   
    
    let navigate = useNavigate(); 
    async function signUp()
    {
        
        

        // const routeChange = () =>{ 
        //     let path = `\login`; 
        //     navigate(path);
        //   }

        let user = {userFullName,userName,userEmail,userPhone,userDOB,userPassword}
        console.log(user);
        let result = await fetch("http://localhost:3000/api/users",{
        method:'POST',
        headers:{
            "Content-Type":"application/json",
            "Accept":"application/json"
        },
        body:JSON.stringify(user)

    });
    result=await result.json();
    console.log("results " ,user);
    alert("Success!");
    navigate('/login');
    }



  return (
    <>
        <NavigationBar action="LET'S START" hidd={true}/>
        <Header flag={false}/>
        <br /><br />
        <div className="col-sm-6 offset-sm-3">
            <h2>Register Here</h2>
            <input type = "text" 
            className="form-control"
            value={userFullName}
            onChange={(e)=>setName(e.target.value)}
            placeholder="Enter your Full Name"
            required />
            <br/>

            <input type = "text" 
            className="form-control"
             placeholder="Enter your Username"
             value={userName}
             onChange={(e)=>setUserName(e.target.value)}
             required />
            <br/>

            <input type = "email"
             className="form-control" 
             placeholder="Enter your Email Address"
             value ={userEmail}
             onChange={(e)=>setUserEmail(e.target.value)} 
             required/>
            <br/>

            <input type = "number"
             className="form-control"
              placeholder="Enter your Phone Number"
              value = {userPhone}
              onChange = {(e)=>setUserPhone(e.target.value)} 
              required/>
            <br/>

            <input type = "date" 
            className="form-control"
             placeholder="Enter your DOB"
             value={userDOB}
             onChange = {(e)=>setUserDOB(e.target.value)}
             required/>
            <br/>

            <input type = "password"
             className="form-control" 
             placeholder="Enter your Password"
             value = {userPassword}
             onChange={(e)=>setUserPassword(e.target.value)}
            />
            <br/>


            <button type="submit" 
            className="btn btn-warning"
            onClick={signUp}
            >
              Submit
            </button>
        </div>
        <Footer />
    </>
  )
}
