import { useEffect, useState } from "react";
import NavigationBar from '../Components/NavigationBar';
import Footer from '../Components/Footer';
import Card from 'react-bootstrap/Card';
import Header from '../Components/Header';
import '../Components/mainbody.css';
function LoginSuccess(){

    let usrEmail = JSON.parse(localStorage.getItem('user-login-info'));
    
   // JSON.parse(usrEmail);
    console.log(usrEmail.userEmail);
    let api = "http://localhost:3000/api/users/"+usrEmail.userEmail;
    console.log("api : ",api)
    let [users,setUsers] = useState([])

    useEffect(()=>{
        fetch(api).then(res=>res.json())
        .then(resdata=>{
            setUsers(resdata.data)
        })
    },[])

console.log("king : ",users)
    return(
        <>
        <NavigationBar action="LET'S START" hidd={true}/>
        <Header flag={true}/>
        <div className="divtable">

        <Card style={{ width: '25rem', height: '15rem' }}>
      <Card.Body>
        <Card.Title>YOUR DETAILS</Card.Title>
        
        <Card.Text className="divcard">
            FullName : {users.userFullName} <br />
            UserName : {users.userName} <br />
            Email Address : {users.userEmail} <br />
            Phone Number : {users.userPhone} <br />
            Date of Birth : {users.userDOB} <br />
            <a href="/" className="divhome">home</a>
        </Card.Text>
      </Card.Body>
    </Card>


       
        
        </div>
        <Footer />
        </>
    )
}

export default LoginSuccess