import React from 'react'
import NavigationBar from '../Components/NavigationBar'
import SubBody from '../Components/SubBody'
import Footer from '../Components/Footer';
import Header from '../Components/Header';

export default function RegisterHome() {
  return (
    <>
        <NavigationBar action="LET'S START" hidd={false}/>
        <Header flag={true}/>
        <SubBody />
        <Footer />
    </>
  )
}