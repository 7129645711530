
import React,{useState} from 'react';
import NavigationBar from '../Components/NavigationBar';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import {useNavigate} from 'react-router-dom';

export default function RegisterHome() {

    const [userEmail,setUserEmail] = useState("");
    const [userPassword,setUserPassword]= useState("");
    
    let navigate = useNavigate(); 
  
    async function userLogin(){
      console.log("DATA ",userEmail,userPassword);
      let user = {userEmail,userPassword}
      let result = await fetch("http://localhost:3000/api/users/login",{
          method:'POST',
          headers:{
              "Content-Type":"application/json",
              "Accept":"application/json"
          },
          body:JSON.stringify(user)
  
      });
      result=await result.json();
      console.log("new result : ",result)
      
      
      if ( result.success !== 1){
        console.log("WRong");
        alert("BAD CREDENTIALS OR USER DOESN'T EXISTS");
        navigate('/login');
      }
      else{
        localStorage.setItem('user-info',JSON.stringify(result));
        localStorage.setItem('user-login-info',JSON.stringify(user));
        alert("LOGIN SUCCESSFULL");
        navigate('/loginSuccess');
      }
     
    }


  return (
    <>
        <NavigationBar action="LET'S START" hidd={true}/>
        <Header flag={false}/>
        <br/><br/><br/>
      <div className="col-sm-6 offset-sm-3">
      <h3>Login Here</h3>
      

      <input type = "email"
       className="form-control" 
       placeholder="Enter your Email Address"
       onChange={(e)=>setUserEmail(e.target.value)} 
       required/>
      <br/>


      <input type = "password"
       className="form-control" 
       placeholder="Enter your Password"
       onChange={(e)=>setUserPassword(e.target.value)} 
       />
      <br/>


      <button type="submit" 
      className="btn btn-warning"
     onClick={userLogin}
      >
        Submit
      </button>
  </div>
        <Footer />
    </>
  )
}