import React from 'react'
import './component.css';

export default function Footer() {
  return (
    
    <div className='footer'>

      <div className='uldiv'>
        <ul className='ulrow'>
            <li className='li'>
                <a href='/' className='atag'>
                    Find a Doctor
                </a>
            </li>
            <li className='li' >
            <a href='/' className='atag'>
                    About Us
            </a>
            </li>
            <li className='li'>
                <a href='/' className='atag'>
                    Contact Us
                </a>
            </li>
        </ul>
      </div>


    <div className='uldiv2'>
        <ul className='ulrow2'>
            <li className='fli'>
                <a href='/' className='classa'>
                    Terms & Privacy
                </a>
            </li>
            
            <li className='fli'>
                <a href='/' className='classa'>
                    Ethics & Compliance
                </a>
            </li>

            <li className='fli'>
                <a href='/' className='classa'>
                    Fraud & Abuse
                </a>
            </li>

            <li className='fli'>
                <a href='/' className='classa'>
                    Feedback
                </a>
            </li>

            <li className='fli'>
                <a href='/' className='classa'>
                    For Developers
                </a>
            </li>

            <li className='fli'>
                <a href='/' className='classa'>
                    Transparency in Coverage
                </a>
            </li>

            <li className='fli'>
                <a href='/' className='classa'>
                    TIC Machine related Files
                </a>
            </li>
        </ul>
    </div>

    <div className='uldiv3'>
    © 2022 Regence health plans are Independent Licensees of the Blue Cross and Blue Shield Association serving members in Idaho, Oregon, Utah and select counties of Washington.
    </div>
    <br />
    <div className='uldiv3'>
    © 2022 Regence are Independent Licensees of the Blue Cross and Blue Shield Association serving members in Idaho, Oregon, Utah and select counties of Washington.
    </div>
    
    </div>
  )
}
