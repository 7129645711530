
import './App.css';
import Home from './MainCompo/Home';
import LoginHome from './MainCompo/LoginHome';
import RegisterHome from './MainCompo/RegisterHome';
import LoginSuccess from './MainCompo/LoginSuccess';
import Entry from './MainCompo/Entry';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
// import { createBrowserRouter , RouterProvider } from 'react-router-dom';


// let myrouter = createBrowserRouter([
//   {
//     path:"/",
//     element:<Entry/>,
//     children:[
//       {
//         path:"/login",
//         element:<LoginHome/>
//       },
//       {
//         path:"/register",
//         element:<RegisterHome/>
//       },
//       {
//         path:"/loginSuccess",
//         element:<LoginSuccess/>
//       }
//     ]
    
//   }
// ])

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="login" element={<LoginHome />} />
        <Route exact path="register" element={<RegisterHome />} />
        <Route exact path="loginSuccess" element={<LoginSuccess />} />
      </Routes>
    </Router>
    // <RouterProvider router={myrouter}/>
  );
}

export default App;
